export const FirebaseConfig = {
	"projectId": "alkila-67b1f",
	"appId": "1:502747862951:web:0e2ab8afb735f1df7e4882",
	"databaseURL": "https://alkila-67b1f-default-rtdb.asia-southeast1.firebasedatabase.app",
	"storageBucket": "alkila-67b1f.appspot.com",
	"locationId": "asia-southeast1",
	"apiKey": "AIzaSyC0QDHwARlZBuEYazIXajFSZJ46YdsJTC0",
	"authDomain": "alkila-67b1f.firebaseapp.com",
	"messagingSenderId": "502747862951",
	"measurementId": "G-ZM6YPHJQFL"
};